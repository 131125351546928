var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-auth" },
    [_c("router-view", { on: { redirect: _vm.redirect } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }