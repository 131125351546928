<template>
  <div class="m-auth">
    <router-view @redirect="redirect"></router-view>
  </div>
</template>

<script>
export default {
  name: "MAuth",
  data() {
    return {};
  },
  methods: {
    async redirect() {
      if (this.$route.query.redirectTo) {
        const href = decodeURIComponent(
          window.location.href.split("redirectTo=")[1]
        );
        const targetHost = href.split("?")[0];
        const paramsToSend = href.split("?")[1];
        if (href === process.env.VUE_APP_FRONTEND_URL) {
          this.conditionalRedirect();
        } else {
          window.location = targetHost + "?" + encodeURI(paramsToSend);
        }
      } else {
        this.conditionalRedirect();
      }
    },
    async conditionalRedirect() {
      let allCookie = document.cookie.split(";");
      for (let cookie of allCookie) {
        if (cookie.trim().startsWith("userRole")) {
          if (cookie.includes("TEACHER_VIEW")) {
            window.location.replace("/teachers");
          } else if (cookie.includes("STUDENT_VIEW")) {
            window.location.replace("/students");
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables.scss";
.m-auth {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff !important;

  .v-dialog {
    width: auto !important;
  }
}
.m-auth__logo {
  height: 62px !important;
  width: 60px !important;
}
.m-auth .v-btn {
  text-transform: uppercase;
}
.m-background-gradient {
  background: linear-gradient(307.73deg, #abe5ff 19.04%, #ffffff 76.67%);
  height: 100vh;
}
.m-acc-illustration-img {
  opacity: 40%;
  filter: blur(1.5px);
  position: absolute;
  height: 65vh;
  /*z-index: 2;*/
  width: 65%;
}
</style>
