// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m-auth {\n  position: absolute;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  z-index: 100;\n  background-color: #fff !important;\n}\n.m-auth .v-dialog {\n  width: auto !important;\n}\n.m-auth__logo {\n  height: 62px !important;\n  width: 60px !important;\n}\n.m-auth .v-btn {\n  text-transform: uppercase;\n}\n.m-background-gradient {\n  background: linear-gradient(307.73deg, #abe5ff 19.04%, #ffffff 76.67%);\n  height: 100vh;\n}\n.m-acc-illustration-img {\n  opacity: 40%;\n  filter: blur(1.5px);\n  position: absolute;\n  height: 65vh;\n  /*z-index: 2;*/\n  width: 65%;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
